<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t("Fields.NewField", {}, { locale: this.$store.state.activeLang })
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="selectedFieldType"
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <SetLoader v-if="!fieldTypes || fieldTypes.length == 0" />
  <div class="exclude-divs pe-2 ps-2" v-else>
    <div class="row mt-3" v-if="!selectedFieldType">
      <div class="col-md-12 mb-3">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <input
            type="text"
            class="form-control"
            :autocomplete="this.$isAutoComplete"
            :spellcheck="this.$isTextSpellCheck"
            @keyup="filterFieldType()"
            v-model="query"
            :placeholder="
              $t(
                'BaseModelFields.Filter',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          />
        </div>
      </div>
      <div
        class="col-md-4 col-xs-12 col-sm-12"
        v-for="fieldType in fieldTypes"
        :key="fieldType.publicId"
      >
        <div
          class="card widget widget-fields"
          :class="String.format('card-{0}', fieldType.name)"
          @click="fieldTypeSelect(fieldType.publicId)"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="
            $t(
              'FieldTypeDescriptions.' + fieldType.description,
              {},
              { locale: this.$store.state.activeLang }
            )
          "
        >
          <div class="card-body">
            <div
              class="widget-fields-container d-flex align-items-center justify-content-start flex-container"
            >
              <div class="widget-fields-icon">
                <div class="image-icon">
                  <i class="bi" :class="$root.getFieldIcon(fieldType.name)"></i>
                </div>
              </div>
              <div class="widget-fields-content d-flex align-items-center">
                <span class="widget-fields-text">{{ fieldType.name }}</span>
              </div>
              <!-- <div class="widget-fields-indicator">
                {{
                  $t(
                    "FieldTypeDescriptions." + fieldType.description,
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" v-else>
      <div class="col-md-12 col-sm-12">
        <div
          class="card widget widget-fields card-changed-field widget-fields-no-scale"
          :class="String.format('card-{0}', this.selectedField.name)"
        >
          <div class="card-body">
            <div
              class="widget-fields-container d-flex align-items-center justify-content-center flex-container"
            >
              <div class="widget-fields-icon">
                <div class="image-icon">
                  <i
                    class="bi"
                    :class="$root.getFieldIcon(selectedField.name)"
                  ></i>
                </div>
              </div>
              <div class="widget-fields-content d-flex align-items-center br-1">
                <span class="widget-fields-text">{{
                  this.selectedField.name
                }}</span>
              </div>
              <div class="widget-fields-indicator">
                {{
                  $t(
                    "FieldTypeDescriptions." + selectedField.description,
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
              <div class="widget-fields-indicator">
                <button
                  type="button"
                  class="btn btn-success btn-no-custom-style btn-sm pb-1px"
                  @click="wizardRestart()"
                >
                  <i class="bi bi-arrow-counterclockwise mx-0"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <form class="form-field-new" novalidate>
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <div v-if="isSuccess" class="alert alert-success">
            {{
              $t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="row row-cols-1">
            <div class="col col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "Fields.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                @keyup="fieldNameKeyup()"
                id="Name"
                v-model="fieldData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "Fields.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                @keyup="formulaNameKeyup()"
                v-model="fieldData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
          </div>
          <div class="row row-cols-1 mt-2">
            <div class="col col-md-12">
              <label for="Description" class="form-label">{{
                $t(
                  "Fields.Description",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <textarea
                name="Description"
                class="form-control"
                id="Description"
                v-model="fieldData.description"
                :spellcheck="this.$isTextSpellCheck"
                rows="1"
              ></textarea>
            </div>
          </div>
          <div class="row row-cols-1 mt-2">
            <div
              class="col"
              :class="
                selectedFieldTypeSelectlist ||
                selectedFieldTypeOrgUnit ||
                selectedFieldTypePredefined ||
                selectedFieldTypeAutoId ||
                selectedFieldTypeFormula ||
                selectedFieldTypeRollup
                  ? 'col-md-12'
                  : 'col-md-6'
              "
            >
              <label for="HelpText" class="form-label"
                >{{
                  $t(
                    "Fields.HelpText",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="HelpText"
                v-model="fieldData.helpText"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col col-md-6" v-if="selectedFieldTypeCheckbox">
              <label for="DefaultValue" class="form-label">{{
                $t(
                  "Fields.DefaultValue",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <div class="col-12">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    name="checkboxDefaultValue"
                    id="checkboxDefaultValueChecked"
                    v-model="fieldData.defaultValue"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxDefaultValueChecked"
                    >{{
                      $t(
                        "Fields.Checked",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    name="checkboxDefaultValue"
                    id="checkboxDefaultValueUnChecked"
                    v-model="fieldData.defaultValue"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxDefaultValueUnChecked"
                    >{{
                      $t(
                        "Fields.Unchecked",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
              </div>
            </div>
            <div class="col col-md-6" v-else-if="selectedFieldTypeSpatial">
              <label for="DefaultValue" class="form-label">{{
                $t(
                  "Fields.DefaultValue",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                @onChange="onChangeSpatial"
                :data="spatialData"
              />
            </div>
            <div
              class="col"
              :class="{
                'col-md-6': !selectedFieldTypeSelectlist,
                'col-md-12 mt-2': selectedFieldTypeSelectlist,
              }"
              v-else-if="
                !selectedFieldTypeOrgUnit &&
                !selectedFieldTypeFormulaOrgUnit &&
                !selectedFieldTypePredefined &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFormula &&
                !selectedFieldTypeRollup
              "
            >
              <label for="DefaultValue" class="form-label"
                >{{
                  $t(
                    "Fields.DefaultValue",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}<a
                  @click="openFormulaEditor"
                  class="cursor-pointer text-decoration-none text-primary ms-1"
                >
                  <span
                    ><img
                      width="20"
                      height="20"
                      :src="
                        require('@/assets/images/icons/function-primary.png')
                      " /></span
                  >&nbsp;
                  {{
                    $t(
                      "Components.FormulaEditorModal.OpenFormulaEditor",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</a
                ></label
              >
              <textarea
                name="DefaultValue"
                class="form-control"
                id="DefaultValue"
                v-model="fieldData.defaultValue"
                :spellcheck="this.$isTextSpellCheck"
                rows="1"
              ></textarea>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="fieldData.isActive"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="!selectedFieldTypeRollup && !selectedFieldTypeReadOnly"
            >
              <div class="form-check">
                <label
                  for="IsEditable"
                  class="form-label"
                  v-if="
                    selectedFieldTypeFormula ||
                    selectedFieldTypeFormulaNumber ||
                    selectedFieldTypeFormulaOrgUnit ||
                    selectedFieldTypeFormulaTextAreaOrSpatial
                  "
                  >{{
                    $t(
                      "Fields.FormulaIsEditableLabelText",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <label for="IsEditable" class="form-label" v-else>{{
                  $t(
                    "Fields.NotFormulaIsEditableLabelText",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsEditable"
                  v-model="fieldData.isEditable"
                />
              </div>
            </div>
            <div class="col-md-6" v-if="isRequiredDisplay">
              <div class="form-check">
                <label for="IsRequired" class="form-label">{{
                  $t(
                    "Fields.IsRequired",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsRequired"
                  v-model="fieldData.isRequired"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-check" v-if="!selectedFieldTypeRollup">
                <label for="IsClientCalculate" class="form-label">{{
                  $t(
                    "Fields.IsClientCalculate",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsClientCalculate"
                  v-model="fieldData.isClientCalculate"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeCheckbox &&
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFile &&
                !selectedFieldTypeEncrypted &&
                !selectedFieldTypeImage &&
                !selectedFieldTypeHtml &&
                !selectedFieldTypeFormulaTextAreaOrSpatial &&
                !selectedFieldTypeReadOnly &&
                !selectedFieldTypeTextArea
              "
            >
              <div class="form-check">
                <label for="IsUnique" class="form-label">{{
                  $t(
                    "Fields.IsUnique",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsUnique"
                  v-model="fieldData.isUnique"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeCheckbox &&
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFile &&
                !selectedFieldTypeImage &&
                !selectedFieldTypeHtml &&
                !this.selectedFieldTypeFormulaTextAreaOrSpatial &&
                !selectedFieldTypeReadOnly
              "
            >
              <div class="form-check">
                <label for="IsCheckUnchanged" class="form-label">{{
                  $t(
                    "Fields.IsCheckUnchanged",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCheckUnchanged"
                  v-model="fieldData.isCheckUnchanged"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeCheckbox &&
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFile &&
                !selectedFieldTypeImage &&
                !selectedFieldTypeHtml &&
                !selectedFieldTypeFormulaTextAreaOrSpatial &&
                !selectedFieldTypeReadOnly &&
                !selectedFieldTypeTextArea
              "
            >
              <div class="form-check">
                <label for="IsUniqueCheckClientCalculate" class="form-label">{{
                  $t(
                    "Fields.IsUniqueCheckClientCalculate",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsUniqueCheckClientCalculate"
                  v-model="fieldData.isUniqueCheckClientCalculate"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeReadOnly &&
                !selectedFieldTypeSelectlist
              "
            >
              <div class="form-check">
                <label for="IsCalculateOnClientChange" class="form-label">{{
                  $t(
                    "Fields.IsCalculateOnClientChange",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCalculateOnClientChange"
                  v-model="fieldData.isCalculateOnClientChange"
                  @change="onChangeCalculateOnClientChange"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="!selectedFieldTypeAutoId && !selectedFieldTypeRollup"
            >
              <div class="form-check">
                <label for="IsCalculatedOnlyOnInsert" class="form-label">{{
                  $t(
                    "Fields.IsCalculatedOnlyOnInsert",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCalculatedOnlyOnInsert"
                  @change="onChangeCalculatedOnlyOnInsert"
                  v-model="fieldData.isCalculatedOnlyOnInsert"
                />
              </div>
            </div>
            <div
              class="col-md-12 mb-3"
              v-if="fieldData.isCalculateOnClientChange"
            >
              <label for="CalculateTriggerField" class="form-label">{{
                $t(
                  "Fields.CalculateTriggerField",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onMultipleSelectChange="
                  onChangeMultipleSelectForCalculateTriggerField
                "
                :isDisabled="
                  !fieldData.isCalculateOnClientChange ? true : false
                "
                :allowEmpty="false"
                :isParameters="true"
                :isMultiple="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryAllFormulizedFieldsWithFormula?parentFormulaName={0}',
                    this.fieldData.customObjectFormulaName
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <div
                class="form-check"
                id="DataReadFromMainTable"
                v-if="selectedFieldTypeLookup"
              >
                <label for="IsDataReadFromMainTable" class="form-label">{{
                  $t(
                    "Fields.IsDataReadFromMainTable",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsDataReadFromMainTable"
                  v-model="fieldData.isDataReadFromMainTable"
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeLookup" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <div class="alert alert-info text-dark mb-0">
                  <i class="bi bi-info-circle"></i>
                  {{
                    $t(
                      "BaseModelFields.SelectlistWarning",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <label for="LookupObject" class="form-label required">{{
                    $t(
                      "Fields.LookupObject",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeLookupObject"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    routeLink="/#/CustomObject/Detail/"
                    :allowEmpty="false"
                    :isGatewayRequest="true"
                    requestUrl="/Lcdp-SummaryCustomObjectsWithFormula"
                  />
                </div>
                <div class="col-md-6">
                  <label for="ViewFilter" class="form-label required">{{
                    $t(
                      "Fields.ViewFilter",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :resetSelect="multipleSelectValueReset"
                    @onChange="onChangeLookupObjectViewFilter"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '/#/CustomObject/EditViewFilter/{0}&viewFilterId=',
                        this.fieldData.lookupObjectPublicId
                      )
                    "
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDisabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.lookupObjectFormulaName
                      )
                        ? true
                        : false
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummarySystemViewFiltersWithFormula?coFormulaName={0}',
                        this.fieldData.lookupObjectFormulaName
                      )
                    "
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <label for="IsMultiple" class="form-label">{{
                    $t(
                      "Fields.MultipleSelect",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsMultiple"
                    v-model="fieldData.isMultiple"
                  />
                </div>
                <div class="form-check">
                  <label for="IsPlusButtonActive" class="form-label">{{
                    $t(
                      "Fields.IsPlusButtonActive",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsPlusButtonActive"
                    v-model="fieldData.isPlusButtonActive"
                  />
                </div>
                <div class="form-check">
                  <label for="IsViewingFilterButtonActive" class="form-label">{{
                    $t(
                      "Fields.IsViewingFilterButtonActive",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsViewingFilterButtonActive"
                    v-model="fieldData.isViewingFilterButtonActive"
                  />
                </div>
                <div class="form-check">
                  <label for="IsSplitSearch" class="form-label">{{
                    $t(
                      "Fields.IsSplitSearch",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsSplitSearch"
                    v-model="fieldData.isSplitSearch"
                  />
                </div>
                <div class="form-check">
                  <label for="IsParentPredefined" class="form-label">{{
                    $t(
                      "Fields.IsParentPredefined",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsParentPredefined"
                    :disabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.lookupObjectFormulaName
                      )
                    "
                    v-model="fieldData.isParentPredefined"
                  />
                </div>
                <div
                  class="row mb-3"
                  v-if="
                    fieldData.isParentPredefined &&
                    !String.isNullOrWhiteSpace(
                      this.fieldData.lookupObjectFormulaName
                    )
                  "
                >
                  <div class="col">
                    <label for="ControlingField" class="form-label required">{{
                      $t(
                        "Fields.ControlingField",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeForControllingFieldPublicId"
                      :allowEmpty="false"
                      :isParameters="true"
                      :isDetailIconShow="true"
                      selectedPrimaryKey="publicId"
                      openDirection="top"
                      :routeLink="
                        String.format(
                          '#/CustomObject/EditField/{0}&fieldId=',
                          this.$route.params.customObjectId
                        )
                      "
                      :isGatewayRequest="true"
                      :requestUrl="
                        String.format(
                          '/Lcdp-LookupControllingItemsWithFormula?childCustomObjectFormulaName={0}&customObjectFormulaName={1}',
                          this.fieldData.lookupObjectFormulaName,
                          this.fieldData.customObjectFormulaName
                        )
                      "
                    />
                  </div>
                  <div class="col">
                    <label
                      for="ControlingRelationField"
                      class="form-label required"
                      >{{
                        $t(
                          "Fields.ControlingRelationField",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeForControllingRelationFieldPublicId"
                      :allowEmpty="false"
                      :isParameters="true"
                      :isDisabled="
                        String.isNullOrWhiteSpace(
                          fieldData.controllingCustomObjectFormulaName
                        )
                      "
                      :isDetailIconShow="true"
                      selectedPrimaryKey="publicId"
                      openDirection="top"
                      :routeLink="
                        String.format(
                          '#/CustomObject/EditField/{0}&fieldId=',
                          fieldData.controllingFieldLookupObjectPublicId
                        )
                      "
                      :isGatewayRequest="true"
                      :requestUrl="
                        String.format(
                          '/Lcdp-SummaryLookupFieldsWithFormula?type=Lookup&parentFormulaName={0}&query={1}',
                          this.fieldData.controllingCustomObjectFormulaName,
                          this.fieldData.lookupObjectFormulaName
                        )
                      "
                    />
                  </div>
                </div>
                <div class="form-check">
                  <label for="IsLookupFieldGroupWanted" class="form-label">{{
                    $t(
                      "Fields.IsLookupFieldGroupWanted",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsLookupFieldGroupWanted"
                    :disabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.viewFilterFormulaName
                      )
                    "
                    v-model="fieldData.isLookupFieldGroupWanted"
                  />
                </div>
                <div
                  class="col-12 mt-1"
                  v-if="
                    fieldData.isLookupFieldGroupWanted &&
                    !String.isNullOrWhiteSpace(
                      this.fieldData.viewFilterFormulaName
                    )
                  "
                >
                  <label
                    for="LookupFieldPublicIds"
                    class="form-label required"
                    >{{
                      $t(
                        "Fields.LookupFieldPublicIds",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onMultipleSelectChange="
                      onChangeMultipleSelectForLookupObjectFields
                    "
                    :allowEmpty="false"
                    :isParameters="true"
                    :isMultiple="true"
                    :isGatewayRequest="true"
                    openDirection="top"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryFiltersFieldsWithFormula?coFormulaName={0}&viewFilterFormulaName={1}',
                        this.fieldData.lookupObjectFormulaName,
                        this.fieldData.viewFilterFormulaName
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeNumber" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="FieldType" class="form-label required">{{
                    $t(
                      "Fields.FieldType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="numberFieldTypes[0]"
                    :data="numberFieldTypes"
                    :allowEmpty="false"
                    @onChange="onChangeNumberFieldType"
                  />
                </div>
                <div
                  class="col-md-6"
                  v-if="!String.isNullOrWhiteSpace(this.fieldData.fieldTypeId)"
                >
                  <label for="Description" class="form-label">{{
                    $t(
                      "Fields.Description",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <div class="alert alert-info mb-0 p-2">
                    <span v-if="this.fieldData.fieldTypeId == 3">{{
                      $t(
                        "FieldTypeDescriptions.decimal_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                    <span v-if="this.fieldData.fieldTypeId == 33">{{
                      $t(
                        "FieldTypeDescriptions.long_integer_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                    <span v-if="this.fieldData.fieldTypeId == 34">{{
                      $t(
                        "FieldTypeDescriptions.double_precision_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                    <span v-if="this.fieldData.fieldTypeId == 8">{{
                      $t(
                        "FieldTypeDescriptions.percent_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  this.fieldData.fieldTypeId != 8 &&
                  !String.isNullOrWhiteSpace(this.fieldData.fieldTypeId)
                "
              >
                <div
                  class="col-12 mt-3"
                  v-if="this.fieldData.fieldTypeId == 33"
                >
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                    />
                  </div>
                </div>
                <div class="col-12 mt-3" v-else>
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                      @change="
                        fieldData.isThousandSeparator
                          ? (this.fieldData.decimalPlaces = 2)
                          : (this.fieldData.decimalPlaces = 0)
                      "
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label for="Length" class="form-label"
                      >{{
                        $t(
                          "Fields.Length",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <h6 class="badge badge-warning text-dark">
                      {{ fieldData.length }}
                    </h6>
                    <input
                      type="range"
                      class="form-range field-length"
                      min="1"
                      max="18"
                      v-model="fieldData.length"
                      @oninput="this.fieldData.length = this.value"
                    />
                  </div>
                  <template v-if="this.fieldData.fieldTypeId != 33">
                    <div
                      class="col-md-6"
                      v-if="this.fieldData.fieldTypeId == 34"
                    >
                      <label for="DecimalPlaces" class="form-label">{{
                        $t(
                          "Fields.DecimalPlaces",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <h6 class="badge badge-warning text-dark">
                        {{ fieldData.decimalPlaces }}
                      </h6>
                      <input
                        type="range"
                        class="form-range field-decimal-places"
                        min="0"
                        max="18"
                        v-model="fieldData.decimalPlaces"
                        @oninput="this.fieldData.decimalPlaces = this.value"
                      />
                    </div>
                    <div class="col-md-6" v-else>
                      <label for="DecimalPlaces" class="form-label">{{
                        $t(
                          "Fields.DecimalPlaces",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <h6 class="badge badge-warning text-dark">
                        {{ fieldData.decimalPlaces }}
                      </h6>
                      <input
                        type="range"
                        class="form-range field-decimal-places"
                        min="0"
                        max="6"
                        v-model="fieldData.decimalPlaces"
                        @oninput="this.fieldData.decimalPlaces = this.value"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeText" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="Length" class="form-label required"
                  >{{
                    $t(
                      "Fields.Length",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  ({{
                    $t(
                      "Fields.Max450Character",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }})</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="Length"
                  v-model="fieldData.length"
                  min="0"
                  max="450"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeTextArea" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="Length" class="form-label required"
                  >{{
                    $t(
                      "Fields.Length",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  {{
                    $t(
                      "Fields.ZeroIsUnlimited",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="Length"
                  v-model="fieldData.length"
                  min="0"
                  max="999999"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeFile" class="col-12">
            <div class="form-check">
              <label for="IsMultiple" class="form-label">{{
                $t(
                  "Fields.MultipleSelect",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsMultiple"
                v-model="fieldData.isMultiple"
              />
            </div>
          </div>

          <div v-if="selectedFieldTypeImage" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="ImageSelection" class="form-label required"
                  >{{
                    $t(
                      "Fields.ImageSelection",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="imageSelectionData[2]"
                  :data="imageSelectionData"
                  :allowEmpty="false"
                  @onChange="onChangeForImageSelection"
                />
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <label for="IsMultiple" class="form-label">{{
                    $t(
                      "Fields.MultipleSelect",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsMultiple"
                    v-model="fieldData.isMultiple"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeHtml" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="Property" class="form-label">{{
                  $t(
                    "Fields.Type",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
                ><br />
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    name="checkboxProperyText"
                    id="checkboxProperyText"
                    v-model="fieldData.property"
                  />
                  <label class="form-check-label" for="checkboxProperyText">{{
                    $t(
                      "Fields.Article",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    name="checkboxProperyDocument"
                    id="checkboxProperyDocument"
                    v-model="fieldData.property"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxProperyDocument"
                    >{{
                      $t(
                        "Fields.Document",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
              </div>
              <div class="col-12 mt-3">
                <label for="Length" class="form-label required"
                  >{{
                    $t(
                      "Fields.Length",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  {{
                    $t(
                      "Fields.ZeroIsUnlimited",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="Length"
                  v-model="fieldData.length"
                  min="0"
                  max="999999"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeSelectlist" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <div class="alert alert-info text-dark mb-0">
                  <i class="bi bi-info-circle"></i>
                  {{
                    $t(
                      "BaseModelFields.SelectlistWarning",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </div>
              </div>
              <div class="col-12 mt-2">
                <label for="SelectListItems" class="form-label required">{{
                  $t(
                    "Fields.SelectListItems",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <textarea
                  name="SelectListItems"
                  v-on:keyup="selectlistItemsOnKeyup"
                  style="height: auto"
                  class="form-control"
                  id="SelectListItems"
                  :spellcheck="this.$isTextSpellCheck"
                  v-model="fieldData.selectListItems"
                  ref="selectListItems"
                  required
                  rows="2"
                ></textarea>
              </div>
              <div
                v-if="!fieldData.SetSelectedItemByFormula"
                class="col-12 mt-3"
              >
                <label for="SelectListSelectedItems" class="form-label">{{
                  $t(
                    "Fields.SelectListSelectedItems",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :data="this.selectlistItems"
                  :isMultiple="true"
                  @onMultipleSelectChange="
                    onChangeMultipleSelectForSelectlistItems
                  "
                  v-if="this.fieldData.isMultiple"
                />
                <FormSelect
                  type="SelectWithLocalData"
                  :data="this.selectlistItems"
                  @onChange="onChangeSelectListItems"
                  v-else
                />
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsShortAlphabetically" class="form-label">{{
                      $t(
                        "Fields.IsShortAlphabetically",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsShortAlphabetically"
                      v-model="fieldData.isShortAlphabetically"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsMultiple" class="form-label">{{
                      $t(
                        "Fields.MultipleSelect",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsMultiple"
                      v-model="fieldData.isMultiple"
                    />
                  </div>
                </div>
                <!-- <div class="col-md-6">
                                    <div class="form-check">
                                        <label for="IsControllingFilterAnd" class="form-label">{{$t('Fields.IsControllingFilterAnd', {}, {locale:this.$store.state.activeLang})}}</label>
                                        <input class="form-check-input" type="checkbox" id="IsControllingFilterAnd" v-model="fieldData.isControllingFilterAnd">
                                    </div>
                                </div> -->
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsRadio" class="form-label">{{
                      $t(
                        "Fields.IsRadio",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsRadio"
                      v-model="fieldData.isRadio"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="SetSelectedItemByFormula" class="form-label">{{
                      $t(
                        "Fields.SetSelectedItemByFormula",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="SetSelectedItemByFormula"
                      v-model="fieldData.SetSelectedItemByFormula"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypePredefined" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <div class="alert alert-info text-dark mb-0">
                  <i class="bi bi-info-circle"></i>
                  {{
                    $t(
                      "BaseModelFields.SelectlistWarning",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <label for="Predefinedlists" class="form-label required">{{
                    $t(
                      "Fields.Predefinedlists",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="predefinedTypeData[0]"
                    @onChange="onChangePredefinedList"
                    :allowEmpty="false"
                    :data="predefinedTypeData"
                  />
                </div>
                <div class="col-md-6" v-if="!fieldData.isParentPredefined">
                  <label for="DefaultValue" class="form-label">{{
                    $t(
                      "Fields.DefaultValue",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>

                  <FormSelect
                    v-if="this.fieldData.isMultiple"
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onMultipleSelectChangeReturnValues="
                      onChangePredefinedMultipleSelectForDefaultValue
                    "
                    :isParameters="true"
                    :isMultiple="true"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryPredefinedItems?parentId={0}',
                        this.fieldData.predefinedPublicId
                      )
                    "
                    :resetSelect="defaultValueReset"
                  />

                  <FormSelect
                    v-else
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangePredefinedForDefaultValue"
                    :resetSelect="defaultValueReset"
                    :isParameters="true"
                    :isMultiple="false"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryPredefinedItems?parentId={0}',
                        this.fieldData.predefinedPublicId
                      )
                    "
                  />
                </div>
                <div class="col-md-6" v-else>
                  <label for="ControlingField" class="form-label required">{{
                    $t(
                      "Fields.ControlingField",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangePrefinedControlling"
                    :resetSelect="defaultValueReset"
                    :isParameters="true"
                    :isMultiple="false"
                    :allowEmpty="false"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-PredefinedControllingItems?childId={0}&customObjectPublicId={1}',
                        this.fieldData.predefinedPublicId,
                        this.$route.params.customObjectId
                      )
                    "
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsMultiple" class="form-label">{{
                      $t(
                        "Fields.MultipleSelect",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsMultiple"
                      v-model="fieldData.isMultiple"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsParentPredefined" class="form-label">{{
                      $t(
                        "Fields.IsParentPredefined",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsParentPredefined"
                      v-model="fieldData.isParentPredefined"
                      @change="onChangeIsParentPredefined"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeEncrypted" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="EncryptedType" class="form-label required">{{
                    $t(
                      "Fields.EncryptedType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="encryptedTypeData[0]"
                    @onChange="onChangeEncryptedTypeData"
                    :allowEmpty="false"
                    :data="encryptedTypeData"
                  />
                </div>
                <div class="col-md-6">
                  <label for="Regex" class="form-label required">{{
                    $t(
                      "Fields.Regex",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Regex"
                    v-model="fieldData.regex"
                    :autocomplete="this.$isAutoComplete"
                    :spellcheck="this.$isTextSpellCheck"
                    required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="ReadMask" class="form-label required">{{
                    $t(
                      "Fields.ReadMask",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="ReadMask"
                    v-model="fieldData.readMask"
                    :autocomplete="this.$isAutoComplete"
                    :spellcheck="this.$isTextSpellCheck"
                    required
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <label for="IsEncrypted" class="form-label">{{
                    $t(
                      "Fields.IsEncrypted",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsEncrypted"
                    v-model="fieldData.isEncrypted"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeRollup" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="FieldType" class="form-label required">{{
                    $t(
                      "Fields.FieldType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="rollupFieldTypes[0]"
                    :data="rollupFieldTypes"
                    :allowEmpty="false"
                    @onChange="onChangeRollupFieldType"
                  />
                </div>
                <div class="col-md-6">
                  <label for="AggregateFunction" class="form-label required">{{
                    $t(
                      "Fields.AggregateFunction",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :resetSelect="aggregateFunctionValueReset"
                    :data="aggregateFunctions"
                    :allowEmpty="false"
                    @onChange="onChangeAggregateFunctions"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="LookupObject" class="form-label required">{{
                    $t(
                      "Fields.LookupObject",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeLookupObject"
                    :allowEmpty="false"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    routeLink="#/CustomObject/Detail/"
                    :isGatewayRequest="true"
                    requestUrl="/Lcdp-SummaryCustomObjectsWithFormula"
                  />
                </div>
                <div class="col-md-6">
                  <label for="ViewFilter" class="form-label required">{{
                    $t(
                      "Fields.ViewFilter",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :resetSelect="multipleSelectValueReset"
                    @onChange="onChangeLookupObjectViewFilter"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
                        this.fieldData.lookupObjectPublicId
                      )
                    "
                    :isDisabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.lookupObjectFormulaName
                      )
                        ? true
                        : false
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummarySystemViewFiltersWithFormula?coFormulaName={0}',
                        this.fieldData.lookupObjectFormulaName
                      )
                    "
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="Fields" class="form-label required">{{
                    $t(
                      "Fields.RollupFields",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :resetSelect="rollupFieldValueReset"
                    @onChange="onChangeRollupField"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditField/{0}&fieldId=',
                        this.fieldData.lookupObjectPublicId
                      )
                    "
                    :isDisabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.viewFilterFormulaName
                      )
                        ? true
                        : false
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryAggregateFieldsWithFormula?parentFormulaName={0}&type={1}&aggregateFunctionId={2}',
                        this.fieldData.lookupObjectFormulaName,
                        this.fieldData.fieldTypeId,
                        this.fieldData.aggregateFunctionId
                      )
                    "
                  />
                </div>
                <div class="col-md-6">
                  <div class="col">
                    <label
                      for="ControlingRelationField"
                      class="form-label required"
                      >{{
                        $t(
                          "Fields.ControlingRelationField",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      :resetSelect="rollupFieldValueReset"
                      @onChange="
                        onChangeForControllingRelationFieldPublicIdRollup
                      "
                      :allowEmpty="false"
                      :isParameters="true"
                      :isDisabled="
                        String.isNullOrWhiteSpace(
                          fieldData.lookupObjectFormulaName
                        )
                      "
                      :isDetailIconShow="true"
                      selectedPrimaryKey="publicId"
                      :routeLink="
                        String.format(
                          '#/CustomObject/EditField/{0}&fieldId=',
                          this.fieldData.lookupObjectPublicId
                        )
                      "
                      :isGatewayRequest="true"
                      :requestUrl="
                        String.format(
                          '/Lcdp-SummaryLookupFieldsWithFormula?type=Lookup&parentFormulaName={0}&query={1}',
                          this.fieldData.customObjectFormulaName,
                          this.fieldData.lookupObjectFormulaName
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="row mt-3"
                v-if="!selectedFieldTypeRollupDateOrDateTime"
              >
                <div class="col-md-6">
                  <label for="Length" class="form-label"
                    >{{
                      $t(
                        "Fields.Length",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.length }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="1"
                    max="18"
                    v-model="fieldData.length"
                    @oninput="this.fieldData.length = this.value"
                  />
                </div>
                <div class="col-md-6">
                  <label for="DecimalPlaces" class="form-label">{{
                    $t(
                      "Fields.DecimalPlaces",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.decimalPlaces }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="0"
                    max="6"
                    v-model="fieldData.decimalPlaces"
                    @oninput="this.fieldData.decimalPlaces = this.value"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div
                  class="col-md-6"
                  v-if="!selectedFieldTypeRollupDateOrDateTime"
                >
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                      @change="
                        fieldData.isThousandSeparator
                          ? (this.fieldData.decimalPlaces = 2)
                          : (this.fieldData.decimalPlaces = 0)
                      "
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsUpdateWholeRecord" class="form-label">{{
                      $t(
                        "Fields.IsUpdateWholeRecord",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsUpdateWholeRecord"
                      v-model="fieldData.isUpdateWholeRecord"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeFormula" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="FieldType" class="form-label required">{{
                    $t(
                      "Fields.FieldType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="formulaFieldTypes[0]"
                    :data="formulaFieldTypes"
                    :allowEmpty="false"
                    @onChange="onChangeFormulaFieldType"
                  />
                </div>
                <div class="col-md-6" v-if="fieldData.isTableRollup">
                  <label for="AggregateFunction" class="form-label">{{
                    $t(
                      "Fields.AggregateFunction",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :data="aggregateFunctions"
                    @onChange="onChangeAggregateFunctions"
                  />
                </div>
              </div>
              <div class="row mt-3" v-if="fieldData.isTableRollup">
                <div class="col-md-6">
                  <label for="TablePublicId" class="form-label">{{
                    $t(
                      "Fields.Table",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeTablePublicId"
                    :isParameters="true"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryFieldsByTypeWithFormula?parentFormulaName={0}&type=Table',
                        this.fieldData.customObjectFormulaName
                      )
                    "
                  />
                </div>
                <div class="col-md-6">
                  <label for="TableFields" class="form-label">{{
                    $t(
                      "Fields.TableFields",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :isDisabled="
                      String.isNullOrWhiteSpace(fieldData.tablePublicId)
                        ? true
                        : false
                    "
                    @onChange="onChangeRollupFieldPublicId"
                    :isParameters="true"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryTableFieldsWithFormula?parentFormulaName={0}&tableId={1}&type=Table',
                        this.fieldData.customObjectFormulaName,
                        this.fieldData.tablePublicId
                      )
                    "
                  />
                </div>
              </div>
              <OrganizationalUnitTab
                v-if="
                  selectedFieldTypeFormulaOrgUnit && organizationalUnitTabShow
                "
                ref="formulaOrganizationalUnitTab"
                :data="fieldData"
              />
              <div v-if="!selectedFieldTypeFormulaOrgUnit" class="row mt-3">
                <div class="col-md-12">
                  <label for="Formula" class="form-label"
                    >{{
                      $t(
                        "Fields.Formula",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}<a
                      @click="openFormulaEditor"
                      class="cursor-pointer text-decoration-none text-primary ms-1"
                    >
                      <span
                        ><img
                          width="20"
                          height="20"
                          :src="
                            require('@/assets/images/icons/function-primary.png')
                          " /></span
                      >&nbsp;
                      {{
                        $t(
                          "Components.FormulaEditorModal.OpenFormulaEditor",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</a
                    ></label
                  >
                  <textarea
                    name="Formula"
                    class="form-control"
                    id="Formula"
                    v-model="fieldData.defaultValue"
                    :spellcheck="this.$isTextSpellCheck"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <div class="row mt-3" v-if="selectedFieldTypeFormulaNumber">
                <div class="col-md-6">
                  <label for="Length" class="form-label"
                    >{{
                      $t(
                        "Fields.Length",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.length }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="1"
                    max="18"
                    v-model="fieldData.length"
                    @oninput="this.fieldData.length = this.value"
                  />
                </div>
                <div class="col-md-6">
                  <label for="DecimalPlaces" class="form-label">{{
                    $t(
                      "Fields.DecimalPlaces",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.decimalPlaces }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="0"
                    max="6"
                    v-model="fieldData.decimalPlaces"
                    @oninput="this.fieldData.decimalPlaces = this.value"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <!-- <div class="col-md-6" v-if="selectedFieldTypeFormulaNumber">
                  <div class="form-check">
                    <label for="IsTableRollup" class="form-label">{{
                      $t(
                        "Fields.IsTableRollup",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsTableRollup"
                      v-model="fieldData.isTableRollup"
                      @change="onChangetableRollup"
                    />
                  </div>
                </div> -->
                <div class="col-md-6" v-if="selectedFieldTypeFormulaNumber">
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                      @change="
                        fieldData.isThousandSeparator
                          ? (this.fieldData.decimalPlaces = 2)
                          : (this.fieldData.decimalPlaces = 0)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeAutoId" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-check">
                    <label
                      for="IsAutoIdTextBasedIncrement"
                      class="form-label"
                      >{{
                        $t(
                          "Fields.IsAutoIdTextBasedIncrement",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsAutoIdTextBasedIncrement"
                      v-model="fieldData.isAutoIdTextBasedIncrement"
                    />
                  </div>
                </div>
                <div
                  class="col-md-6"
                  v-if="fieldData.isAutoIdTextBasedIncrement"
                >
                  <label
                    for="AutoIdIncrementField"
                    class="form-label required"
                    >{{
                      $t(
                        "Fields.AutoIdIncrementField",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeAutoIdIncrementField"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditField/{0}&fieldId=',
                        this.$route.params.customObjectId
                      )
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryFieldsWithFormula?customObjectFormulaName={0}',
                        this.fieldData.customObjectFormulaName
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <OrganizationalUnitTab
            v-if="selectedFieldTypeOrgUnit && organizationalUnitTabShow"
            ref="organizationalUnitTab"
            :data="fieldData"
          />

          <ActionButtons
            v-if="$isMobile()"
            @methodSave="onSubmit('0', 'btn-save')"
            @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
            @clear="onClear()"
            :isNewRecord="true"
          />
        </form>
      </div>
    </div>
  </div>
  <AlertModal
    messageKey="Fields.IsCalculatedOnlyOnInsertUnCheckedMessage"
    @yes="calculatedOnlyOnInsertYes"
    @no="calculatedOnlyOnInsertNo"
  />
  <FormulaEditorModal
    :formulaField="formulaField"
    parentRecordPrefix=""
    @updateFormula="updateFormula"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
import { removeTooltips } from "@/core/helpers/dom";
import AlertModal from "@//components/custom/alert/AlertModal.vue";
import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
import OrganizationalUnitTab from "@/components/custom/custom-settings/OrganizationalUnitTab.vue";
import $ from "jquery";
export default {
  name: "CustomObjectNewField",
  components: { AlertModal, FormulaEditorModal, OrganizationalUnitTab },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      isSuccess: false,
      fieldData: {
        isActive: true,
        isEditable: false,
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
      },
      organizationalUnitTabShow: true,
      imageSelectionData: [
        {
          key: "only_view_on_detail",
          value: this.$t(
            "Fields.OnylViewOnDetail",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "only_view_on_modal",
          value: this.$t(
            "Fields.OnlyViewOnModal",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "only_view_on_download",
          value: this.$t(
            "Fields.OnlyViewOnDownload",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      predefinedTypeData: [
        {
          key: "4A01FDD1F1894532A3CC15DBF04D68FA",
          value: this.$t(
            "Fields.TurkeyCities",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4FA4DE40FC4D4899BF540905800282EB",
          value: this.$t(
            "Fields.TurkeyCounties",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "09E8E285197849829B55333C924E024A",
          value: this.$t(
            "Fields.TurkeyTowns",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "38E1F4B4B774411593056EACE62C2A10",
          value: this.$t(
            "Fields.TurkeyDistricts",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "C3CECD15054F48D4B01882FAFA217B28",
          value: this.$t(
            "Fields.TurkeyPostalCodes",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "65274AE7E3AB455B977C4306177B656C",
          value: this.$t(
            "Fields.Currencies",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "D341199798EB4BC29358CD7512241429",
          value: this.$t(
            "Fields.Languages",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "289B0F8684F0433D9FCE7A37DD64A363",
          value: this.$t(
            "Fields.CountriesInTurkish",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "49ED54DF0A47415BB4726316E6762872",
          value: this.$t(
            "Fields.CountriesInEnglish",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      encryptedTypeData: [
        {
          key: "1",
          value: this.$t(
            "Fields.EncryptedCreditCard",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "Fields.EncryptedEmail",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "Fields.EncryptedPassword",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      rollupFieldTypes: [
        { key: "23", value: "Rollup" },
        { key: "28", value: "RollupDate" },
        { key: "29", value: "RollupDateTime" },
      ],
      formulaFieldTypes: [
        { key: "18", value: "FormulaString" },
        { key: "24", value: "FormulaNumber" },
        { key: "25", value: "FormulaDateTime" },
        { key: "26", value: "FormulaDate" },
        { key: "30", value: "FormulaTextArea" },
        { key: "31", value: "FormulaSpatial" },
        { key: "32", value: "FormulaOrganizationalUnit" },
      ],
      numberFieldTypes: [
        { key: "3", value: "Decimal" },
        { key: "33", value: "Long Integer" },
        { key: "34", value: "Double Precision" },
        { key: "8", value: "Percent" },
      ],
      spatialData: [
        { key: "CURRENT_USER_LOCATION", value: "CURRENT_USER_LOCATION" },
      ],
      aggregateFunctions: [],
      summary: {
        key: "1",
        value: this.$t(
          "Fields.Sum",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      avarage: {
        key: "2",
        value: this.$t(
          "Fields.Avarage",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      count: {
        key: "3",
        value: this.$t(
          "Fields.Count",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      max: {
        key: "4",
        value: this.$t(
          "Fields.Max",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      min: {
        key: "5",
        value: this.$t(
          "Fields.Min",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      query: "",
      nullObject: [{ key: null, value: null }],
      selectedFieldType: false,

      selectedFieldTypeNumber: false,
      selectedFieldTypeText: false,
      selectedFieldTypeTextArea: false,
      selectedFieldTypeLookup: false,
      selectedFieldTypeCheckbox: false,
      selectedFieldTypeFile: false,
      selectedFieldTypeImage: false,
      selectedFieldTypeHtml: false,
      selectedFieldTypeSelectlist: false,
      selectedFieldTypeOrgUnit: false,
      selectedFieldTypePredefined: false,
      selectedFieldTypeEncrypted: false,
      selectedFieldTypeSpatial: false,
      selectedFieldTypeAutoId: false,
      selectedFieldTypeReadOnly: false,

      selectedFieldTypeRollup: false,
      selectedFieldTypeRollupDateOrDateTime: false,

      selectedFieldTypeFormula: false,
      selectedFieldTypeFormulaTextAreaOrSpatial: false,
      selectedFieldTypeFormulaNumber: false,
      selectedFieldTypeFormulaOrgUnit: false,

      isRequiredDisplay: true,
      isClientCalculateDisplay: true,
      fieldTypes: [],
      selectedField: {},

      multipleSelectValueReset: false,
      defaultValueReset: false,
      aggregateFunctionValueReset: false,
      rollupFieldValueReset: false,

      selectlistItems: [],
      formulaField: {},
    };
  },
  methods: {
    openFormulaEditor() {
      this.formulaField = this.fieldData;
      if (this.selectedFieldTypeFormulaOrgUnit) {
        this.formulaField.formula =
          this.$refs.formulaOrganizationalUnitTab.tabData.defaultValue;
      } else {
        this.formulaField.formula = this.fieldData.defaultValue;
      }
      showModal(document.getElementById("modalFormulaEditor"));
    },
    updateFormula(formula) {
      this.fieldData.defaultValue = formula;
      if (this.selectedFieldTypeFormulaOrgUnit) {
        this.$refs.formulaOrganizationalUnitTab.tabData.defaultValue = formula;
      }
    },
    onChangeCalculatedOnlyOnInsert(e) {
      if (!this.fieldData.isCalculatedOnlyOnInsert) {
        $("#message-modal").click();
      }
    },
    calculatedOnlyOnInsertNo() {
      this.fieldData.isCalculatedOnlyOnInsert = true;
    },
    calculatedOnlyOnInsertYes() {
      this.fieldData.isCalculatedOnlyOnInsert = false;
    },
    onChangeForControllingFieldPublicId(selected) {
      this.fieldData.controllingPublicId = selected.publicId;
      this.fieldData.controllingCustomObjectFormulaName = selected.key;
      this.fieldData.controllingName = selected.value;
      if (!String.isNullOrWhiteSpace(selected.key)) {
        this.$prodGatewayAxios
          .get(String.format("/Lcdp-FieldGet?id={0}", selected.publicId))
          .then((response) => {
            this.fieldData.controllingFieldLookupObjectPublicId =
              response.data.lookupObjectPublicId;
          })
          .catch(function (error) {
            //alert(error);
          });
      } else {
        this.fieldData.controllingFieldLookupObjectFormulaName = null;
      }
    },
    onChangeForControllingRelationFieldPublicId(selected) {
      this.fieldData.controllingObjectFieldFormulaName = selected.key;
    },
    onChangeForControllingRelationFieldPublicIdRollup(selected) {
      this.fieldData.controllingObjectFieldFormulaName = selected.key;
    },
    onChangeControlling(selected) {
      this.fieldData.controllingPublicId = selected.key;
      //this.fieldData.controllingCustomObjectFormulaName = selected.key;
    },
    onChangeAutoIdIncrementField(selected) {
      // this.fieldData.autoIdIncrementFieldPublicId = selected.key;
      this.fieldData.autoIdIncrementFieldFormulaName = selected.key;
      this.fieldData.autoIdIncrementFieldName = selected.value;
    },
    onChangePredefinedMultipleSelectForDefaultValue(values) {
      this.fieldData.defaultValue = values;
    },
    onChangePredefinedForDefaultValue(selected) {
      this.fieldData.defaultValue = selected.value;
    },
    onChangeIsParentPredefined() {
      this.fieldData.defaultValue = null;
      this.fieldData.controllingPublicId = null;
      this.fieldData.controllingCustomObjectFormulaName = null;
    },
    onChangePrefinedControlling(selected) {
      this.fieldData.controllingPublicId = selected.key;
      this.fieldData.controllingCustomObjectFormulaName = null;
    },
    onChangeOrganizationalUnitGroups(ids) {
      this.fieldData.organizationalUnitGroupPublicIds = ids;
    },
    onChangeForImageSelection(selected) {
      this.fieldData.imageSelection = selected.key;
      this.fieldData.property = selected.key;
    },
    onChangeMultipleSelectForCalculateTriggerField(ids) {
      // this.fieldData.calculateTriggerFieldPublicIds = ids;
      this.fieldData.calculateTriggerFieldFormulaNames = ids;
    },
    onChangeCalculateOnClientChange() {
      if (!this.fieldData.isCalculateOnClientChange) {
        // this.fieldData.calculateTriggerFieldPublicIds = null;
        this.fieldData.calculateTriggerFieldFormulaNames = null;
      }
    },
    onChangePredefinedList(selected) {
      this.fieldData.predefinedPublicId = selected.key;
      this.defaultValueReset = !this.defaultValueReset;
    },
    onChangeLookupObject(selected) {
      this.fieldData.lookupObjectPublicId = selected.publicId;
      this.fieldData.lookupObjectFormulaName = selected.key;
      this.multipleSelectValueReset = !this.multipleSelectValueReset;
      this.rollupFieldValueReset = !this.rollupFieldValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeLookupObjectViewFilter(this.nullObject);
      }
    },
    onChangeEncryptedTypeData(selected) {
      if (String.isNullOrWhiteSpace(selected.key) || selected.key === "1") {
        this.fieldData.regex = "####-##**-****-####";
        this.fieldData.readMask = "*";
      } else if (selected.key === "2") {
        this.fieldData.regex = "*";
        this.fieldData.readMask = "*";
      } else {
        this.fieldData.regex = "?";
        this.fieldData.readMask = "*";
      }
    },
    onChangeSpatial(selected) {
      this.fieldData.defaultValue = selected.key;
    },
    onChangeLookupObjectViewFilter(selected) {
      // this.fieldData.viewFilterPublicId = selected.key;
      this.fieldData.viewFilterFormulaName = selected.key;
      this.rollupFieldValueReset = !this.rollupFieldValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeRollupField(this.nullObject);
      }
    },
    onChangeMultipleSelectForLookupObjectFields(ids) {
      this.fieldData.lookupFieldPublicIds = ids;
      // this.fieldData.lookupFieldFormulaNames = ids;
    },
    onChangeRollupFieldType(selected) {
      this.fieldData.fieldTypeId = selected.key;
      this.aggregateFunctionValueReset = !this.aggregateFunctionValueReset;

      this.aggregateFunctions = [];
      if (selected.key == "23") {
        this.aggregateFunctions.push(this.summary);
        this.aggregateFunctions.push(this.avarage);
        this.aggregateFunctions.push(this.count);
        this.aggregateFunctions.push(this.min);
        this.aggregateFunctions.push(this.max);

        this.fieldData.decimalPlaces = 0;
        this.fieldData.length = 12;
        this.selectedFieldTypeRollupDateOrDateTime = false;
      } else {
        this.aggregateFunctions.push(this.min);
        this.aggregateFunctions.push(this.max);

        this.selectedFieldTypeRollupDateOrDateTime = true;
        this.fieldData.decimalPlaces = 0;
        this.fieldData.length = 0;
      }
    },
    onChangeRollupField(selected) {
      this.fieldData.rollupFieldFormulaName = selected.key;
    },
    onChangeSelectListItems: function (selected) {
      this.fieldData.selectListSelectedItems = selected.key;
    },
    onChangeMultipleSelectForSelectlistItems: function (ids) {
      this.fieldData.selectListSelectedItems = ids;
    },
    onChangeAggregateFunctions(selected) {
      this.fieldData.aggregateFunctionId = selected.key;
    },
    onChangeFormulaFieldType(selected) {
      this.fieldData.fieldTypeId = selected.key;

      this.fieldData.decimalPlaces = 0;
      this.fieldData.length = 0;
      this.isRequiredDisplay = false;

      if (selected.key == "30" || selected.key == "31") {
        this.selectedFieldTypeFormulaTextAreaOrSpatial = true;

        this.selectedFieldTypeFormulaOrgUnit = false;
        this.selectedFieldTypeFormulaNumber = false;
      } else if (selected.key == "24") {
        this.selectedFieldTypeFormulaNumber = true;
        this.fieldData.length = 12;

        this.selectedFieldTypeFormulaTextAreaOrSpatial = false;
        this.selectedFieldTypeFormulaOrgUnit = false;

        this.aggregateFunctions.push(this.summary);
        this.aggregateFunctions.push(this.avarage);
        this.aggregateFunctions.push(this.count);
        this.aggregateFunctions.push(this.min);
        this.aggregateFunctions.push(this.max);
      } else if (selected.key == "32") {
        this.selectedFieldTypeFormulaOrgUnit = true;

        this.selectedFieldTypeFormulaTextAreaOrSpatial = false;
        this.selectedFieldTypeFormulaNumber = false;
      } else {
        this.selectedFieldTypeFormulaTextAreaOrSpatial = false;
        this.selectedFieldTypeFormulaOrgUnit = false;
        this.selectedFieldTypeFormulaNumber = false;
      }
    },
    onChangeNumberFieldType(selected) {
      this.fieldData.fieldTypeId = selected.key;
      this.fieldData.decimalPlaces = 0;
      this.fieldData.length = 0;
    },
    onChangetableRollup() {
      this.fieldData.tablePublicId = null;
      this.fieldData.aggregateFunction = null;
      this.fieldData.rollupFieldFormulaName = null;
    },
    onChangeTablePublicId(selected) {
      this.fieldData.tablePublicId = selected.key;
    },
    onChangeRollupFieldPublicId(selected) {
      // this.fieldData.RollupFieldPublicId = selected.key;
      this.fieldData.rollupFieldFormulaName = selected.key;
    },
    selectlistItemsOnKeyup(event) {
      if (
        event.which === 188 ||
        event.which === 110 ||
        event.keyCode === 188 ||
        event.keyCode === 110
      ) {
        //block key , | todo:buraya tekrar bakılıcak
        this.fieldData.selectListItems = this.fieldData.selectListItems.slice(
          0,
          -1
        );
        return false;
      }

      var values = this.fieldData.selectListItems.split("\n");
      this.$refs.selectListItems.setAttribute("rows", values.length + 1);

      this.selectlistItems = [];
      values
        .filter((f) => {
          return !String.isNullOrWhiteSpace(f);
        })
        .forEach((v) => {
          this.selectlistItems.push({ key: v, value: v });
        });
    },
    fieldNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.fieldData.name)) {
        this.fieldData.formulaName = "";
        return;
      }
      this.fieldData.formulaName = String.replaceAlphanumeric(
        this.fieldData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.fieldData.formulaName = String.replaceAlphanumeric(
        this.fieldData.formulaName,
        "_"
      ).toUpperCase();
    },
    getFieldTypes() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldListFieldTypes"))
        .then((response) => {
          var fieldTypes = response.data.filter((items) => {
            return (
              !items.name.includes("Formula") &&
              !items.name.includes("Rollup") &&
              items.name !== "DoublePrecision" &&
              items.name !== "LongInteger" &&
              items.name !== "Percent"
            );
          });

          fieldTypes.push({
            publicId: "formula",
            name: "Formula",
            description: "formula_description",
          });

          fieldTypes.push({
            publicId: "rollup",
            name: "Rollup",
            description: "rollup_description",
          });

          this.fieldTypes = fieldTypes;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    wizardRestart() {
      this.onClear();

      this.selectedFieldType = false;
      this.selectedField = {};
      this.query = "";
      this.fieldData.fieldTypeId = 0;
      this.fieldData.defaultValue = "";

      this.isRequiredDisplay = true;
      this.isClientCalculateDisplay = true;

      this.selectedFieldTypeText = false;
      this.selectedFieldTypeTextArea = false;
      this.selectedFieldTypeNumber = false;
      this.selectedFieldTypeCheckbox = false;
      this.selectedFieldTypeFile = false;
      this.selectedFieldTypeImage = false;
      this.selectedFieldTypeLookup = false;
      this.selectedFieldTypeSelectlist = false;
      this.selectedFieldTypeOrgUnit = false;
      this.selectedFieldTypePredefined = false;
      this.selectedFieldTypeEncrypted = false;
      this.selectedFieldTypeSpatial = false;
      this.selectedFieldTypeAutoId = false;
      this.selectedFieldTypeRollup = false;
      this.selectedFieldTypeRollupDateOrDateTime = false;
      this.selectedFieldTypeFormula = false;
      this.selectedFieldTypeFormulaNumber = false;
      this.selectFieldTypeFormulaTextAreaOrSpatial = false;
      this.selectedFieldTypeFormulaOrgUnit = false;
      this.selectedFieldTypeHtml = false;
      this.selectedFieldTypeReadOnly = false;

      this.multipleSelectValueReset = false;
      this.defaultValueReset = false;
      this.aggregateFunctionValueReset = false;
      this.rollupFieldValueReset = false;

      this.selectlistItems = [];
    },
    displayElements(fieldTypePublicId) {
      switch (fieldTypePublicId) {
        case "F5463851A46141B993329865552D6AA5": //text
          this.fieldData.length = 450;
          this.selectedFieldTypeText = true;
          break;
        case "2416415DD0FA4534B56C8104566A3552":
          this.selectedFieldTypeReadOnly = true;
          this.isRequiredDisplay = false;
          break;
        case "8F165733822047A4BDF411EA023275AE": //number
          this.selectedFieldTypeNumber = true;
          this.fieldData.decimalPlaces = 0;
          this.fieldData.length = 12;
          this.fieldData.fieldTypeId = this.numberFieldTypes[0].key;
          break;
        case "E72484640E1A441A99859AE1B90D0A72": //textarea
          this.fieldData.length = 0;
          this.selectedFieldTypeTextArea = true;
          break;
        case "EC0C6CFBF7E14B90920C40F87D5A8437": //selectlist
          this.selectedFieldTypeSelectlist = true;
          break;
        case "28E7CF7CA3BD44BBBAFFF421C0C57CBB": //OrganizationalUnit
          this.selectedFieldTypeOrgUnit = true;
          break;
        case "B3C3E94A66E84D498E07FB4F58D5432C": //Predefined
          this.selectedFieldTypePredefined = true;
          this.fieldData.predefinedPublicId = this.predefinedTypeData[0].key;
          break;
        case "E514A565F849442DA922DA85BCDF5727": //lookup
          this.selectedFieldTypeLookup = true;
          this.fieldData.isPlusButtonActive = true;
          this.fieldData.isViewingFilterButtonActive = true;
          this.fieldData.isSplitSearch = true;
          break;
        case "Rollup":
        case "Formula":
          this.isRequiredDisplay = false;
          break;
        case "2277176236BF49659F5D50800F6D2304": //checkbox
          this.isRequiredDisplay = false;
          this.selectedFieldTypeCheckbox = true;
          this.fieldData.defaultValue = "false";
          break;
        case "F7E1788E0D0940FF84CFE282521AA25F": //email
        case "0D31C812FB494E26B310F74F09588D7A": //tel
        case "F236DBED57BB4A5ABD34C188BB51BB1B": //url
        case "842F1E2B1F4F4177B3085D985C549108": //datetime
        case "E4A6089950B7497A8B648A536E2298EF": //date
        case "E51E2D25E1D34CDCA8ACE16424EA1485": //time
          this.isClientCalculateDisplay = false;
          break;
        case "2062D2C135124833B5CD12788299026D": //file
          this.isClientCalculateDisplay = false;
          this.selectedFieldTypeFile = true;
          break;
        case "4D04E23B4BAD4856AEF205A4F7D9BF6F": //image
          this.fieldData.property = "only_view_on_download";
          this.isClientCalculateDisplay = false;
          this.selectedFieldTypeImage = true;
          break;
        case "CA4962657C7247D1805ACFF52D599D04": //html
          this.isClientCalculateDisplay = false;
          this.selectedFieldTypeHtml = true;
          this.fieldData.property = "1";
          this.fieldData.length = 0;
          break;
        case "B3C3E94A66E84D498E07FB4F58D45679": //encrypted
          this.selectedFieldTypeEncrypted = true;
          this.fieldData.regex = "####-##**-****-####";
          this.fieldData.readMask = "*";
          break;
        case "B26766C8443E4DF8BB9AB403354E314E": //Spatial
          this.selectedFieldTypeSpatial = true;
          break;
        case "D8F461D7A16D4BE193AA50741D17DFDF": //autoid
          this.selectedFieldTypeAutoId = true;
          this.isRequiredDisplay = false;
          break;
        case "rollup":
          this.selectedFieldTypeRollup = true;
          this.isRequiredDisplay = false;
          this.isCalculateOnClientChange = false;
          this.fieldData.fieldTypeId = this.rollupFieldTypes[0].key;
          this.fieldData.decimalPlaces = 0;
          this.fieldData.length = 12;

          this.aggregateFunctions.push(this.summary);
          this.aggregateFunctions.push(this.avarage);
          this.aggregateFunctions.push(this.count);
          this.aggregateFunctions.push(this.min);
          this.aggregateFunctions.push(this.max);
          break;
        case "formula":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          this.fieldData.fieldTypeId = this.formulaFieldTypes[0].key;
          break;
        default:
          break;
      }

      // if (!this.selectedFieldTypeAutoId && !this.selectedFieldTypeRollup) {
      //   this.fieldData.isCalculatedOnlyOnInsert = true;
      // }
      this.fieldData.isCalculatedOnlyOnInsert = false;
    },
    fieldTypeSelect(fieldTypePublicId) {
      this.selectedField = this.fieldTypes.find(
        (f) => f.publicId == fieldTypePublicId
      );
      this.fieldData = {
        isActive: true,
        isEditable: false,
        customObjectPublicId: this.fieldData.customObjectPublicId,
        customObjectFormulaName: this.fieldData.customObjectFormulaName,
        fieldTypeId: this.selectedField.id,
      };
      this.selectedFieldType = true;
      this.fieldData.fieldTypeId = this.selectedField.id;
      this.displayElements(fieldTypePublicId);

      removeTooltips();
      window.scrollTo(0, 0);
    },
    onClear() {
      this.fieldData = {
        isActive: true,
        isEditable: false,
        customObjectPublicId: this.fieldData.customObjectPublicId,
        customObjectFormulaName: this.fieldData.customObjectFormulaName,
        fieldTypeId: this.selectedField.id,
      };
      this.displayElements(this.selectedField.publicId);
      $(".form-field-new").removeClass("was-validated");
    },
    filterFieldType() {
      var value = this.query.toLowerCase();
      $(".widget-fields").filter(function () {
        var parentDiv = $(this).parent("div");
        parentDiv.toggle(
          $(this)
            .find(".widget-fields-text")
            .text()
            .trim()
            .toLowerCase()
            .indexOf(value) > -1
        );
      });
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-field-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var model = { ...this.fieldData };
      model.isEditable = !this.fieldData.isEditable;

      var organizationalUnitTab = null;
      if (this.selectedFieldTypeFormulaOrgUnit) {
        organizationalUnitTab = this.$refs.formulaOrganizationalUnitTab;
      } else if (this.selectedFieldTypeOrgUnit) {
        organizationalUnitTab = this.$refs.organizationalUnitTab;
      }

      if (organizationalUnitTab) {
        model = {
          ...model,
          ...organizationalUnitTab.tabData,
        };
      }

      model.isMultipleValue = model.isMultiple;

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-FieldNew", model)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/Fields/" + this.$route.params.customObjectId
              );
            } else {
              if (organizationalUnitTab) {
                this.organizationalUnitTabShow = false;
                setTimeout(() => {
                  this.organizationalUnitTabShow = true;
                }, 300);
              }
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getFieldTypes();
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.fieldData.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.fieldData.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
};
</script>
